import * as React from "react";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import { Timeline } from "react-twitter-widgets";
import ReactPlayer from "react-player";
import * as styles from "../components/Index.module.css";

const Section = ({ children }) => (
  <section
    className={styles.content}
    data-sal="slide-up"
    data-sal-easing="ease"
  >
    {children}
  </section>
);

const IndexPage = () => {
  return (
    <Layout pageTitle="">
      <section className={styles.content}>
        <div className={styles.flex}>
          <div>
            <StaticImage height="800" src="../images/hero.png" />
          </div>
          <p className={styles.heroText}>Dominate The World</p>
        </div>
      </section>
      <StaticImage
        className={styles.separator}
        src="../images/yellowCyan.png"
      />

      <div className={styles.cyan}>
        <h2 className={styles.heading}>Who Is Yoshgan Tzarino?</h2>
        <Section>
          <div className={styles.flex}>
            <div>
              <StaticImage
                height="1800"
                src="../images/stand.png"
                placeholder="none"
              />
            </div>
            <div className={styles.textWrapper}>
              <p>
                VTuber。
                <br />
                異世界から世界征服に来た魔王。しかし、この世界のゲーム・プログラミングといった文化に感銘を受け、この世界に沿った方法で世界征服をすることに。
                <br />
                「芳賀(よしが)ツァリノ」と名乗り学生に扮し、大学で潜入調査を行いながらプログラミングとゲームのスキルを磨いている。
              </p>
            </div>
          </div>
        </Section>
      </div>
      <StaticImage src="../images/cyanYellow.png" />

      <h2 className={styles.heading}>YouTube</h2>
      <Section>
        <div className={styles.playerWrapper}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=o0XCkmZ_t3I"
            width="100%"
            height="100%"
          />
        </div>
      </Section>
      <StaticImage
        className={styles.separator}
        src="../images/yellowCyan.png"
      />

      <div className={styles.cyan}>
        <h2 className={styles.heading}>Twitter</h2>
        <Section>
          <div className={styles.timelineWrapper}>
            <Timeline
              dataSource={{
                sourceType: "profile",
                screenName: "Yoshgan_Tzarino",
              }}
              options={{
                height: "600",
                align: "center",
                width: "100%",
              }}
            />
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default IndexPage;
